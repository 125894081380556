import React from "react";
import { graphql, PageProps } from "gatsby";
import { GetBebeleteQuery } from "../../graphql-types";
import Img from "gatsby-image";
import styled from "styled-components";
import SEO from "../components/SEO";

const BebeleteGrid = styled.div`
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
`;

interface ISingleBebeletePage extends PageProps {
  data: GetBebeleteQuery;
}

export default function SingleBebeletePage(props: ISingleBebeletePage) {
  const bebelete = props.data.bebelete;
  return (
    <>
      <SEO title={bebelete.name} image={bebelete.image.asset.fluid.src} />
      <div className="center">
        <Img fluid={bebelete.image.asset.fluid} />
        <div>
          <h2 className="mark">{bebelete.name}</h2>
          <p>{bebelete.description}</p>
        </div>
      </div>
    </>
  );
}

export const query = graphql`
  query GetBebelete($slug: String!) {
    bebelete: sanityPerson(slug: { current: { eq: $slug } }) {
      name
      id
      description
      slug {
        current
      }
      image {
        asset {
          fluid(maxWidth: 1000, maxHeight: 800) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
`;
